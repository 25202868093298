/* @import url('https://fonts.googleapis.com/css2?family=Lilita+One&family=Oleo+Script+Swash+Caps&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Oleo+Script+Swash+Caps&display=swap');

/* NAVBAR STYLe */

/* width */
.scrollable::-webkit-scrollbar {
  width: 12px;
}

/* Track */
.scrollable::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
.scrollable::-webkit-scrollbar-thumb {
  background-color: #324160;
  border-radius: 5px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #4c5976;
}

.trigger {
  padding: 0 10px 0 0;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
  /* height: 80px */
}

.ant-layout-header {
  /* height: 100px !important; */
  /* padding: 0 35px !important; */
}

.ant-layout-sider-zero-width-trigger {
  top: 10px !important;
  background-color: #fff !important;
  color: black !important;
  right: -47px !important;
}

.App {
  text-align: center;
}

/* Scanable File Upload */
.scanable-file-upload {
  border: none;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #fff;
  border: 1px solid gray;
  transition: 1s;
  margin-bottom: 5px;
  cursor: pointer;
  color: #000;
  font-weight: bold;
}

#scanable-file {
  display: none;
}

/* .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(134, 134, 134, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
} */

[class~='ant-menu-horizontal'] {
  border: none;
}

/* .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu */
[class~='ant-menu-horizontal'] .ant-menu-item::after,
[class~='ant-menu-horizontal'] .ant-menu-submenu-title::after {
  /* border-bottom: 1px solid #c91c1c;
  border-right: 1px solid #cf3232; */
  left: 8px;
  right: 8px;
  padding: 0 8px;
}

/* .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu */
[class~='ant-menu-horizontal']:not(.ant-menu-dark)>.ant-menu-item,
[class~='ant-menu-horizontal']:not(.ant-menu-dark)>.ant-menu-submenu {
  padding: 0 8px;
}

[class~='ant-table-cell'] {
  font-size: smaller;
}

/* .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td */
[class~='ant-table-thead']>tr>th,
[class~='ant-table-tbody']>tr>td,
[class~='ant-table tfoot']>tr>th,
[class~='ant-table tfoot']>tr>td {
  padding: 6px 4px !important;
}

.ant-drawer-body {
  padding: 10px 24px;
}

.ant-table-footer {
  padding: 8px 0px;
  background: none;
}

.ant-table.ant-table-bordered>.ant-table-footer {
  border: none;
}

.ant-card-hoverable {
  cursor: default;
}

.ant-card-body {
  padding: 18px;
}

.customPopupTitle {
  font-weight: 900;
  margin: 0px 4px 0px 0px;
}


.ant-card-head {
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid white;
}

.ant-card-head-title {
  padding: 8px 0px;
}

.ant-card.ant-card-bordered {
  box-shadow: 0 1px 2px -2px rgb(0 0 0 / 2%), 0 3px 6px 0 rgb(0 0 0 / 2%), 0 5px 12px 4px rgb(0 0 0 / 2%);
}

/* ant-card-bordered ant-card-hoverable */

.ant-popover-title {
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid white;
}

.ant-modal-header {
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid white;
}

.ant-drawer-header {
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid white;
}

.ant-list-item-meta-content {
  width: 100% !important;
}

.ant-btn>.anticon,
.ant-tag>.anticon {
  display: inline-flex !important;
  align-items: center !important;
}

.ant-typography-copy,
.ant-segmented-item-icon {
  display: inline-flex !important;
  align-items: center !important;
}

.ant-segmented-item-label {
  display: flex !important;
  align-items: center !important;
}

/* .ant-segmented-item:hover {
  color: rgb(255, 255, 255);
}

.ant-segmented-item:focus {
  color: rgb(255, 255, 255);
}

[class~='ant-segmented-item'].ant-segmented-item-selected {
  color: rgb(0, 0, 0) !important;
}

[class~='ant-segmented-item'].ant-segmented-item-selected:hover {
  color: rgb(0, 0, 0) !important;
} */

/******************
*** OFFICE MARKER ***
*******************/
.createdOfficeMarker {
  background-image: url('./Assets/locationMarkers/CreatedOffice.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.allocatedOfficeMarker {
  background-image: url('./Assets/locationMarkers/AllocatedOffice.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.onGoingOfficeMarker {
  background-image: url('./Assets/locationMarkers/StartedOffice.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.holdOfficeMarker {
  background-image: url('./Assets/locationMarkers/HoldOffice.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.finishedOfficeMarker {
  background-image: url('./Assets/locationMarkers/FinishedOffice.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.canceledOfficeMarker {
  background-image: url('./Assets/locationMarkers/CanceledOffice.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.inactiveOfficeMarker {
  background-image: url('./Assets/locationMarkers/InactiveOffice.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}


/******************
*** BANK MARKER ***
*******************/
.createdBankMarker {
  background-image: url('./Assets/locationMarkers/CreatedBank.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.allocatedBankMarker {
  background-image: url('./Assets/locationMarkers/AllocatedBank.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.onGoingBankMarker {
  background-image: url('./Assets/locationMarkers/StartedBank.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.holdBankMarker {
  background-image: url('./Assets/locationMarkers/HoldBank.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.finishedBankMarker {
  background-image: url('./Assets/locationMarkers/FinishedBank.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.canceledBankMarker {
  background-image: url('./Assets/locationMarkers/CanceledBank.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.inactiveBankMarker {
  background-image: url('./Assets/locationMarkers/InactiveBank.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}


/******************
*** RENTAL MARKER ***
*******************/
.createdRentalMarker {
  background-image: url('./Assets/locationMarkers/CreatedRental.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.allocatedRentalMarker {
  background-image: url('./Assets/locationMarkers/AllocatedRental.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.onGoingRentalMarker {
  background-image: url('./Assets/locationMarkers/StartedRental.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.holdRentalMarker {
  background-image: url('./Assets/locationMarkers/HoldRental.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.finishedRentalMarker {
  background-image: url('./Assets/locationMarkers/FinishedRental.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.canceledRentalMarker {
  background-image: url('./Assets/locationMarkers/CanceledRental.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.inactiveRentalMarker {
  background-image: url('./Assets/locationMarkers/InactiveRental.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

/************************
*** HOME/HOUSE MARKER ***
*************************/
.createdHomeMarker {
  background-image: url('./Assets/locationMarkers/CreatedHome.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.allocatedHomeMarker {
  background-image: url('./Assets/locationMarkers/AllocatedHome.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.onGoingHomeMarker {
  background-image: url('./Assets/locationMarkers/StartedHome.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.holdHomeMarker {
  background-image: url('./Assets/locationMarkers/HoldHome.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.finishedHomeMarker {
  background-image: url('./Assets/locationMarkers/FinishedHome.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.canceledHomeMarker {
  background-image: url('./Assets/locationMarkers/CanceledHome.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.inactiveHomeMarker {
  background-image: url('./Assets/locationMarkers/InactiveHome.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

/******************
*** CAR MARKER ***
*******************/
.createdCarQuatationMarker {
  background-image: url('./Assets/locationMarkers/CreatedCar.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.allocatedCarQuatationMarker {
  background-image: url('./Assets/locationMarkers/AllocatedCar.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.onGoingCarQuatationMarker {
  background-image: url('./Assets/locationMarkers/StartedCar.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.holdCarQuatationMarker {
  background-image: url('./Assets/locationMarkers/HoldCar.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.finishedCarQuatationMarker {
  background-image: url('./Assets/locationMarkers/FinishedCar.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.canceledCarQuatationMarker {
  background-image: url('./Assets/locationMarkers/CanceledCar.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.inactiveCarQuatationMarker {
  background-image: url('./Assets/locationMarkers/InactiveCar.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}


/************************
*** DOCUMENT MARKER ***
*************************/
.createdDocumentMarker {
  background-image: url('./Assets/locationMarkers/CreatedDocument.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.allocatedDocumentMarker {
  background-image: url('./Assets/locationMarkers/AllocatedDocument.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.onGoingDocumentMarker {
  background-image: url('./Assets/locationMarkers/StartedDocument.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.holdDocumentMarker {
  background-image: url('./Assets/locationMarkers/HoldDocument.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.finishedDocumentMarker {
  background-image: url('./Assets/locationMarkers/FinishedDocument.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.canceledDocumentMarker {
  background-image: url('./Assets/locationMarkers/CanceledDocument.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.inactiveDocumentMarker {
  background-image: url('./Assets/locationMarkers/InactiveDocument.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}



.commonMarker {
  background-image: url('./Assets/locationMarkers/commonMarker.png');
  background-size: cover;
  width: 20px;
  height: 27px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.endMarker {
  background-image: url('./Assets/locationMarkers/end.png');
  background-size: cover;
  width: 12px;
  height: 12px;
  z-index: 1;
  /* border-radius: 50%; */
  cursor: pointer;
}

.verifierMarker {
  background-image: url('./Assets/locationMarkers/verifier.png');
  background-size: cover;
  width: 30px;
  height: 40px;
  /* border-radius: 50%; */
  cursor: pointer;
}


@media (max-width: 767px) {
  .hidden-mobile {
    display: none !important;
  }
}

.ant-message-custom-content {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* Change color for the active dot */
.slick-dots li.slick-active .custom-dot {
  background-color: #1677ff !important;
  color: #fff !important
}